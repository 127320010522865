import { inject, Injectable, signal } from '@angular/core'
import { Router } from '@angular/router'

interface RouteParams {
  clientId: string
  channelId: string
}

@Injectable({
  providedIn: 'root',
})
export class RouteParamService {
  private router = inject(Router)
  routeParams = signal(this.getRouteParasmFromURL(this.router.url))

  private getRouteParasmFromURL(url: string): RouteParams {
    const pathSegments = url.split('/')
    return {
      clientId: pathSegments[1],
      channelId: pathSegments[2],
    }
  }
}
